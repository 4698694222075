import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Translation, TranslocoLoader } from '@jsverse/transloco';
import { map, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ResponseDto } from '../../shared/models/response-dto.model';
import { CacheService } from '../cache/cache.service';
import { SKIP_API_URL } from '../interceptor/http.interceptor';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private http = inject(HttpClient);
  private cacheService = inject(CacheService);

  getTranslation(lang: string) {
    if (environment.externalTranslations) {
      return this.getRemoteTranslations(lang);
    }

    return this.getLocalTranslations(lang);
  }

  private getRemoteTranslations(lang: string) {
    let headers = new HttpHeaders();

    const etagKey = `etag-label-${lang}`;
    const labelsKey = `label-${lang}`;

    const etag = this.cacheService.get<string>(etagKey);

    if (etag) {
      headers = headers.set('If-None-Match', etag);
    }

    headers = headers.set('Accept-Language', lang);

    return this.http
      .get<ResponseDto<Translation>>(`/labels/web`, {
        headers,
        observe: 'response',
      })
      .pipe(
        tap((response) => {
          if (response.status === 200) {
            const newEtag = response.headers.get('etag');

            this.cacheService.set(labelsKey, response.body?.data);

            if (newEtag) {
              this.cacheService.set(etagKey, newEtag);
            }
          }
        }),
        map(() => {
          const labels = this.cacheService.get<Record<string, string>>(labelsKey);

          return {
            ...labels,
          };
        })
      );
  }

  private getLocalTranslations(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/web/${lang}/labels.json`, {
      context: new HttpContext().set(SKIP_API_URL, true),
    });
  }
}
