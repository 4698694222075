import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs';

import { ChatService } from '../service/chat.service';
import { loadChatQAActions } from './chat.actions';

@Injectable()
export class ChatEffects {
  private readonly actions$ = inject(Actions);
  private readonly chatService = inject(ChatService);

  createQAChat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadChatQAActions.createQAChat),
      switchMap(({ message, bundleId, agent }) =>
        this.chatService.createQAChat(message, bundleId, agent).pipe(
          map((chat) =>
            loadChatQAActions.createQAChatSuccess({
              chat,
            })
          )
        )
      )
    )
  );
}
