import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { filter, map, of, take, tap } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

export const RoleGuard: CanActivateFn | CanActivateChildFn = (route) => {
  const router = inject(Router);

  const { roles } = route.data;

  return inject(AuthenticationService).user$.pipe(
    filter(Boolean),
    take(1),
    map((user) => !roles || roles.includes(user.role)),
    tap((hasAccess: boolean) => {
      if (!hasAccess) {
        router.navigate(['/']);
      }

      return of(hasAccess);
    })
  );
};
