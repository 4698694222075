import { NgClass, NgStyle } from '@angular/common';
import { Component, signal } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';
import { UiKitBaseComponent } from '../../../core/ui-kit/components/ui-kit-base/ui-kit-base.component';
import { RoleDirective } from '../../../features/authentication/directives/role.directive';
import { ALL_ROLES, Role } from '../../../features/authentication/enums/role.enum';
import { NewChatDialogComponent } from '../new-chat-dialog/new-chat-dialog.component';
import { UserProfileComponent } from '../user-profile/user-profile.component';
import { SidebarMenuItem } from './models/sidebar-menu-item.model';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    NgClass,
    NgStyle,
    RouterLink,
    RouterLinkActive,
    TranslocoPipe,
    UserProfileComponent,
    NewChatDialogComponent,
    RoleDirective,
  ],
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent extends UiKitBaseComponent {
  isCollapsed = signal(false);

  menuItems: SidebarMenuItem[] = [
    {
      pageId: 'op__chat',
      link: '/chat',
      icon: 'mdi-message',
      title: 'sidebar.menu.chat',
      roles: [...ALL_ROLES],
    },
    {
      pageId: 'op__dashboard',
      link: '/dashboard',
      icon: 'mdi-view-dashboard',
      title: 'sidebar.menu.dashboard',
      roles: [...ALL_ROLES],
    },
    {
      pageId: 'op__repository',
      link: '/repositories',
      icon: 'mdi-folder-multiple',
      title: 'sidebar.menu.repositories',
      roles: [Role.ADMIN, Role.OPERATOR],
    },
    {
      pageId: 'op__operator',
      link: '/operators',
      icon: 'mdi-account-multiple',
      title: 'sidebar.menu.operators',
      roles: [Role.ADMIN],
    },
    {
      pageId: 'op__agents',
      link: '/agents',
      icon: 'mdi-puzzle',
      title: 'sidebar.menu.agents',
      roles: [Role.ADMIN],
    },
    // {
    //   pageId: 'op__support',
    //   icon: 'mdi-lifebuoy',
    //   title: 'sidebar.menu.support',
    //   roles: [Role.ADMIN],
    // },
  ];

  toggleSidebar(): void {
    this.isCollapsed.update((collapsed) => !collapsed);
  }
}
