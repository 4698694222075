import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { provideState } from '@ngrx/store';

import { provideEffects } from '@ngrx/effects';
import { ChatEffects } from './+state/chat.effects';
import * as fromChat from './+state/chat.reducer';

export function provideChat(): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideState(fromChat.CHAT_FEATURE_KEY, fromChat.chatReducer),
    provideEffects(ChatEffects),
  ]);
}
