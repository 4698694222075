import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import { ReportService } from '../service/report.service';
import { loadReportActions } from './report.actions';
import { selectReportsCache } from './report.selectors';

@Injectable()
export class ReportEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly reportService = inject(ReportService);

  loadReportByToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadReportActions.loadReportByToken),
      withLatestFrom(this.store.select(selectReportsCache)),
      switchMap(([{ token }, cacheMap]) => {
        const cachedReport = cacheMap.get(token);

        if (cachedReport) {
          return of({ report: cachedReport, token });
        }

        return this.reportService.getReportByToken(token).pipe(
          map((report) => ({
            report,
            token,
          })),
          catchError(() => of({ report: null, token: null }))
        );
      }),
      map(({ report, token }) => {
        if (!report && !token) {
          return loadReportActions.loadReportByTokenError();
        }

        return loadReportActions.loadReportByTokenSuccess({
          token,
          report,
        });
      })
    )
  );
}
