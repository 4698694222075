import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { map, switchMap, tap } from 'rxjs';
import { AlertService } from '../../../../core/ui-kit/services/alert.service';
import { BundleService } from '../service/bundle.service';
import { bundleUploadDocumentsActions, loadBundleActions, loadBundleDocumentPreviewActions } from './bundle.actions';

@Injectable()
export class BundleEffects {
  private readonly actions$ = inject(Actions);
  private readonly bundleService = inject(BundleService);
  private readonly alertService = inject(AlertService);

  loadBaseBundles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadBundleActions.loadBaseBundles),
      switchMap(({ page, departmentId, query }) =>
        this.bundleService
          .getBaseBundles(page, undefined, departmentId, query)
          .pipe(map((bundles) => loadBundleActions.loadBaseBundlesSuccess({ bundles })))
      )
    )
  );

  deleteBundleByID$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadBundleActions.deleteBundle),
      switchMap(({ id, departmentId }) =>
        this.bundleService.deleteBundleById(id).pipe(map(() => loadBundleActions.loadBaseBundles({ departmentId })))
      )
    )
  );

  createBundle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadBundleActions.createBundle),
      switchMap(({ payload, departmentId }) =>
        this.bundleService.createBundle(payload).pipe(
          tap(() => this.alertService.showOperationCompletedAlert()),
          map(() => loadBundleActions.loadBaseBundles({ departmentId }))
        )
      )
    )
  );

  loadDocumentPreview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadBundleDocumentPreviewActions.loadDocumentPreview),
      switchMap(({ bundleId, document }) =>
        this.bundleService
          .getDocumentPreviewUrl(bundleId, document.id)
          .pipe(
            map((previewResponse) =>
              loadBundleDocumentPreviewActions.loadDocumentPreviewFromSignedURL({ previewResponse })
            )
          )
      )
    )
  );

  loadDocumentPreviewSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadBundleDocumentPreviewActions.loadDocumentPreviewFromSignedURL),
      switchMap(({ previewResponse }) => {
        const fileMetadata = previewResponse.fileMetadata;

        const bundleId = fileMetadata['x-goog-meta-bundleId'];
        const fileId = fileMetadata['x-goog-meta-fileId'];
        const tenantId = fileMetadata['x-goog-meta-tenantId'];
        const agentIds = fileMetadata['x-goog-meta-agentIds'];
        const fileName = fileMetadata['x-goog-meta-fileName'];

        return this.bundleService
          .getFilePreviewFromSignedUrl(previewResponse.link, bundleId, fileId, tenantId, agentIds, fileName)
          .pipe(map((document) => loadBundleDocumentPreviewActions.loadDocumentPreviewSuccess({ document })));
      })
    )
  );

  showUploadFilesDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(bundleUploadDocumentsActions.showUploadFilesDialog),
        tap(() => {
          const toggleBtn = document.querySelector('#open-upload-bundle-file-dialog') as HTMLButtonElement;

          if (!toggleBtn) {
            console.error(
              'Element with ID `open-upload-bundle-file-dialog` not found. Please add it to open the files upload dialog'
            );
          } else {
            toggleBtn.click();
          }
        })
      ),
    { dispatch: false }
  );
}
