import { NgIf } from '@angular/common';
import { Component, inject, signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { delay } from 'rxjs';
import { LoaderService } from '../../../core/loader/loader.service';

@Component({
  selector: 'app-loader',
  standalone: true,
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  imports: [NgIf, LottieComponent],
})
export class LoaderComponent {
  private readonly loaderService = inject(LoaderService);

  loading = toSignal(this.loaderService.loading$.pipe(delay(0)));

  options: WritableSignal<AnimationOptions> = signal({
    path: '/assets/lottie/loader.json',
  });
}
