import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { provideState } from '@ngrx/store';

import { provideEffects } from '@ngrx/effects';
import { ReportEffects } from './+state/report.effects';
import * as fromReport from './+state/report.reducer';

export function provideReport(): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideState(fromReport.REPORT_FEATURE_KEY, fromReport.reportReducer),
    provideEffects(ReportEffects),
  ]);
}
