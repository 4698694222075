import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { provideState } from '@ngrx/store';

import { provideEffects } from '@ngrx/effects';
import { OperatorEffects } from './+state/operator.effects';
import * as fromOperator from './+state/operator.reducer';

export function provideOperator(): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideState(fromOperator.OPERATOR_FEATURE_KEY, fromOperator.operatorReducer),
    provideEffects(OperatorEffects),
  ]);
}
