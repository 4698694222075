import { NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';
import { DialogRef } from '@ngneat/dialog';
import { filter } from 'rxjs';
import { UiKitBaseComponent } from '../../../core/ui-kit/components/ui-kit-base/ui-kit-base.component';
import { AuthenticationService } from '../../../features/authentication/services/authentication.service';
import { OperatorFacade } from '../../data/data-access-operator/+state/operator.facade';

@Component({
  selector: 'app-user-profile-dialog',
  standalone: true,
  imports: [NgIf, TranslocoPipe],
  templateUrl: './user-profile-dialog.component.html',
  styleUrls: ['./user-profile-dialog.component.scss'],
})
export class UserProfileDialogComponent extends UiKitBaseComponent {
  private readonly authService = inject(AuthenticationService);
  private readonly operatorFacade = inject(OperatorFacade);
  private readonly router = inject(Router);
  private readonly ref = inject(DialogRef);

  user = toSignal(this.authService.user$.pipe(filter(Boolean)));

  logout(): void {
    this.authService.logout().subscribe(() => {
      this.router.navigate(['/login']).then(() => {
        this.close();
      });
    });
  }

  close(): void {
    this.ref.close();
  }

  sendUpdatePasswordLink(): void {
    if (this.user()) {
      this.operatorFacade.sendResetPasswordLink(this.user()!.email);
    }
  }
}
