import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { EnvironmentProviders, isDevMode, LOCALE_ID, makeEnvironmentProviders } from '@angular/core';
import { provideTransloco, TranslocoService } from '@jsverse/transloco';
import { TranslocoHttpLoader } from './transloco-loader';

registerLocaleData(localeIt);

export function provideI18n(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: LOCALE_ID,
      useFactory: (translocoService: TranslocoService) => translocoService.getActiveLang(),
      deps: [TranslocoService],
    },
    provideTransloco({
      config: {
        availableLangs: ['en-US', 'it-IT'],
        defaultLang: 'it-IT',
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
  ]);
}
