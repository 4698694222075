import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { provideState } from '@ngrx/store';

import { provideEffects } from '@ngrx/effects';
import { SummaryEffects } from './+state/summary.effects';
import * as fromSummary from './+state/summary.reducer';

export function provideSummary(): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideState(fromSummary.SUMMARY_FEATURE_KEY, fromSummary.summaryReducer),
    provideEffects(SummaryEffects),
  ]);
}
