import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { provideState } from '@ngrx/store';

import { provideEffects } from '@ngrx/effects';
import { RepositoryEffects } from './+state/repository.effects';
import * as fromRepository from './+state/repository.reducer';

export function provideRepository(): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideState(fromRepository.REPOSITORY_FEATURE_KEY, fromRepository.repositoryReducer),
    provideEffects(RepositoryEffects),
  ]);
}
