import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Paginate } from '../../../models/pagination.model';
import { ResponseDto } from '../../../models/response-dto.model';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../../utils/pagination.utils';
import { CreateRepositoryPayload } from '../models/create-repository-payload.model';
import { Repository } from '../models/repository.model';

@Injectable({
  providedIn: 'root',
})
export class RepositoryService {
  private readonly http = inject(HttpClient);

  getRepositories(
    page = DEFAULT_PAGE,
    pageSize = DEFAULT_PAGE_SIZE,
    counts?: boolean,
    query?: string
  ): Observable<Paginate<Repository>> {
    let params = new HttpParams().set('page', page).set('pageSize', pageSize);

    if (counts) {
      params = params.set('counts', true);
    }

    if (query) {
      params = params.set('name', query);
    }

    return this.http
      .get<ResponseDto<Paginate<Repository>>>(`/management-departments`, { params })
      .pipe(map((response) => response.data));
  }

  getRepositoryById(id: string): Observable<Repository> {
    return this.http
      .get<ResponseDto<Repository>>(`/management-departments/${id}`)
      .pipe(map((response) => response.data));
  }

  deleteRepositoryById(id: string): Observable<void> {
    return this.http.delete<void>(`/management-departments/${id}`);
  }

  createRepository(payload: CreateRepositoryPayload): Observable<Repository> {
    return this.http
      .post<ResponseDto<Repository>>(`/management-departments`, payload)
      .pipe(map((response) => response.data));
  }
}
