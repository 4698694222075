import { AsyncPipe, NgIf, TitleCasePipe } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { DialogService } from '@ngneat/dialog';
import { filter, take } from 'rxjs';
import { UiKitBaseComponent } from '../../../core/ui-kit/components/ui-kit-base/ui-kit-base.component';
import { AuthenticationService } from '../../../features/authentication/services/authentication.service';
import { UserProfileDialogComponent } from '../user-profile-dialog/user-profile-dialog.component';

@Component({
  selector: 'app-user-profile',
  standalone: true,
  imports: [NgIf, AsyncPipe, TitleCasePipe],
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent extends UiKitBaseComponent {
  private readonly authService = inject(AuthenticationService);
  private readonly dialogService = inject(DialogService);

  @Input() small?: boolean;

  user$ = this.authService.user$.pipe(filter(Boolean), take(1));

  openProfileDialog(): void {
    const dialogRef = this.dialogService.open(UserProfileDialogComponent, {
      width: '60vw',
      data: {},
    });
  }
}
