<app-loader></app-loader>

<router-outlet></router-outlet>

<ng-container *ngIf="showAlert() as alert">
  <div class="alert-area" *ngIf="alert.show">
    <app-ui-kit-alert [type]="configuration.type" *ngIf="alert.configuration as configuration">
      <span class="font-bold" *ngIf="configuration.title">
        {{ configuration.title | transloco }}
      </span>
      {{ configuration.message | transloco }}
    </app-ui-kit-alert>
  </div>
</ng-container>

<app-confirm-dialog></app-confirm-dialog>
