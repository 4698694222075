import { NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRouteSnapshot, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { filter, map } from 'rxjs';
import { ChatService } from '../../data/data-access-chat';
import { ChatHistoryComponent, SHOW_CHAT_HISTORY_DATA_KEY } from '../chat-history/chat-history.component';
import { SidebarComponent } from '../sidebar/sidebar.component';

@Component({
  selector: 'app-dashboard-layout',
  standalone: true,
  imports: [NgIf, RouterOutlet, SidebarComponent, ChatHistoryComponent],
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss'],
})
export class DashboardLayoutComponent {
  private readonly chatService = inject(ChatService);
  private readonly router = inject(Router);

  chatId = toSignal(this.chatService.chatId$);

  showChatHistory = toSignal(
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.getCurrentRoute(this.router.routerState.snapshot.root)),
      map((route) => !!route.data[SHOW_CHAT_HISTORY_DATA_KEY])
    )
  );

  private getCurrentRoute(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    while (route.firstChild) {
      route = route.firstChild;
    }

    return route;
  }
}
