import { Component, ElementRef, inject, OnDestroy, OnInit, signal, viewChild } from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';
import { filter, Subject, takeUntil } from 'rxjs';
import { UiKitBaseComponent } from '../../../core/ui-kit/components/ui-kit-base/ui-kit-base.component';
import { Timeout } from '../../decorators/timeout.decorator';
import { ConfirmDialogService } from './confirm-dialog.service';

@Component({
  selector: 'app-confirm-dialog',
  standalone: true,
  imports: [TranslocoPipe],
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent extends UiKitBaseComponent implements OnInit, OnDestroy {
  private readonly confirmDialogService = inject(ConfirmDialogService);
  private readonly destroy$ = new Subject<void>();

  openModalBtn = viewChild<ElementRef>('openModalBtn');

  message = signal<string>('');
  confirmButtonLabel = signal<string>('confirm-dialog.labels.confirm');
  cancelButtonLabel = signal<string>('confirm-dialog.labels.cancel');

  ngOnInit(): void {
    this.confirmDialogService.confirmDialogOptions$
      .pipe(takeUntil(this.destroy$), filter(Boolean))
      .subscribe(({ message, confirmButtonLabel, cancelButtonLabel }) => {
        this.message.set(message);

        confirmButtonLabel && this.confirmButtonLabel.set(confirmButtonLabel);
        cancelButtonLabel && this.cancelButtonLabel.set(cancelButtonLabel);

        this.openDialog();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setAction(action: boolean): void {
    this.confirmDialogService.setResult(action);
  }

  @Timeout()
  private openDialog(): void {
    this.openModalBtn()?.nativeElement.click();
  }
}
