import { NgIf } from '@angular/common';
import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { RouterOutlet } from '@angular/router';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { filter } from 'rxjs';
import { ALLOWED_LANGUAGES, Language } from './core/enums/language.enum';
import { UiKitAlertComponent } from './core/ui-kit/components/ui-kit-alert/ui-kit-alert.component';
import { UiKitBaseComponent } from './core/ui-kit/components/ui-kit-base/ui-kit-base.component';
import { AlertService } from './core/ui-kit/services/alert.service';
import { AuthenticationService } from './features/authentication/services/authentication.service';
import { ConfirmDialogComponent } from './shared/components/confirm-dialog/confirm-dialog.component';
import { LoaderComponent } from './shared/components/loader/loader.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [NgIf, RouterOutlet, UiKitAlertComponent, ConfirmDialogComponent, TranslocoPipe, LoaderComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent extends UiKitBaseComponent implements OnInit {
  private readonly alertService = inject(AlertService);
  private readonly translocoService = inject(TranslocoService);
  private readonly authService = inject(AuthenticationService);

  private readonly destroyRef = inject(DestroyRef);

  title = 'klens-frontend';
  showAlert = toSignal(this.alertService.showAlert$);

  ngOnInit(): void {
    this.setDefaultLanguage();

    this.authService.user$.pipe(takeUntilDestroyed(this.destroyRef), filter(Boolean)).subscribe((user) => {
      this.translocoService.setActiveLang(user.language);
    });
  }

  /**
   * If the user is not logged then set the language to the browser's one,
   * if it's not valid then use the fallback it-IT
   */
  private setDefaultLanguage(): void {
    const browserLanguage = navigator.language as Language;

    if (!ALLOWED_LANGUAGES.includes(browserLanguage)) {
      this.translocoService.setActiveLang(Language.IT);
    } else {
      this.translocoService.setActiveLang(browserLanguage);
    }
  }
}
