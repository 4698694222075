<div
  id="new-chat"
  tabindex="-1"
  aria-hidden="true"
  class="hidden overflow-y-auto overflow-x-hidden bg-dark-purple-100/50 dark:bg-dark-purple-700/50 backdrop-blur-md fixed top-0 right-0 left-0 flex justify-center items-center w-full md:inset-0 h-screen transition-all duration-500 ease-out z-50"
  #newChatDialog
>
  <div
    class="relative p-4 w-10/12 max-w-2xl h-28 max-h-full bg-dark-purple-50 dark:bg-dark-purple-900 rounded-lg flex justify-center items-center"
  >
    <div class="w-full">
      <app-chat-input
        [agents]="agents()"
        [selectedAgent]="selectedAgent()"
        (selectAgent)="setSelectedAgent($event)"
        (sendMessage)="createNewChat($event)"
      ></app-chat-input>
    </div>

    <div data-modal-hide="new-chat" class="hidden" #hideModalBtn></div>
  </div>
</div>
