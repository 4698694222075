<ng-container *ngIf="user$ | async as user">
  <div class="mb-2-2 w-full" *ngIf="!small; else smallUserProfile">
    <button
      id="dropdownUsersButton"
      class="relative w-full text-dark-purple-500 bg-dark-purple-50 font-medium hover:font-bold rounded-lg text-sm px-5 py-2.5 flex items-center dark:bg-dark-purple-900 dark:hover:bg-dark-purple-800 transition-all ease-in-out delay-150"
      type="button"
      (click)="openProfileDialog()"
    >
      <span
        class="bg-violet-500 rounded-full w-7 h-7 p-1.5 mr-3 font-bold text-xs text-white flex items-center justify-center"
      >
        {{ user.avatar }}
      </span>
      <span class="truncate"> {{ user.fullName }}</span>
      <span class="mdi mdi-chevron-right ml-3 ms-auto"></span>
    </button>
  </div>

  <ng-template #smallUserProfile>
    <div class="mb-2 w-full">
      <button
        class="relative w-full text-dark-purple-500 bg-dark-purple-50 font-medium hover:font-bold rounded-lg text-sm px-5 py-2.5 flex items-center dark:bg-dark-purple-900 dark:hover:bg-dark-purple-800 transition-all ease-in-out delay-150"
        type="button"
        (click)="openProfileDialog()"
      >
        <span
          class="bg-violet-500 rounded-full min-w-7 min-h-7 w-7 h-7 p-1.5 font-bold text-xs text-white flex items-center justify-center"
        >
          {{ user.avatar }}
        </span>
      </button>
    </div>
  </ng-template>
</ng-container>
