import { Component, computed, effect, ElementRef, inject, signal, untracked, viewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UiKitBaseComponent } from '../../../core/ui-kit/components/ui-kit-base/ui-kit-base.component';
import { AuthenticationService } from '../../../features/authentication/services/authentication.service';
import { ChatService } from '../../data/data-access-chat/service/chat.service';
import { Nullable } from '../../types/nullable.type';
import { getDefaultAgent } from '../../utils/agent.utils';
import { ChatInputComponent } from '../chat-input/chat-input.component';

@Component({
  selector: 'app-new-chat-dialog',
  standalone: true,
  imports: [ChatInputComponent],
  templateUrl: './new-chat-dialog.component.html',
  styleUrls: ['./new-chat-dialog.component.scss'],
})
export class NewChatDialogComponent extends UiKitBaseComponent {
  private readonly authService = inject(AuthenticationService);
  private readonly chatService = inject(ChatService);

  private hideModalBtn = viewChild<ElementRef>('hideModalBtn');

  user = toSignal(this.authService.user$);

  agents = computed(() => this.user()?.verticals || []);
  selectedAgent = signal<Nullable<string>>(null);

  constructor() {
    super();

    effect(() => {
      if (this.user()) {
        untracked(() => {
          const defaultAgent = getDefaultAgent(this.user()?.verticals || []);

          defaultAgent && this.selectedAgent.set(defaultAgent);
        });
      }
    });
  }

  createNewChat(message: string) {
    this.chatService.createNewChat(message, this.selectedAgent()!).subscribe();
    this.closeModal();
  }

  setSelectedAgent(agent: string) {
    this.selectedAgent.set(agent);
  }

  private closeModal(): void {
    this.hideModalBtn()?.nativeElement.click();
  }
}
