import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Role } from '../../../../features/authentication/enums/role.enum';
import { Paginate } from '../../../../shared/models/pagination.model';
import { ResponseDto } from '../../../../shared/models/response-dto.model';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../../../shared/utils/pagination.utils';
import { CreateOperatorPayload } from '../models/create-operator-payload.model';
import { IOperator, Operator } from '../models/operator.model';
import { UpdateOperatorPayload } from '../models/update-operator-payload.model';

@Injectable({
  providedIn: 'root',
})
export class OperatorService {
  private readonly http = inject(HttpClient);

  getOperators(page = DEFAULT_PAGE, pageSize = DEFAULT_PAGE_SIZE, query?: string): Observable<Paginate<Operator>> {
    let params = new HttpParams().set('page', page).set('pageSize', pageSize).set('roleName', Role.OPERATOR);

    if (query) {
      params = params.set('search', query);
    }

    return this.http.get<ResponseDto<Paginate<IOperator>>>(`/management-users`, { params }).pipe(
      map((response) => response.data),
      map((paginatedData) => ({
        ...paginatedData,
        items: paginatedData.items.map((item) => Operator.Build(item)),
      }))
    );
  }

  getOperatorById(id: string): Observable<Operator> {
    return this.http
      .get<ResponseDto<IOperator>>(`/management-users/${id}`)
      .pipe(map((response) => Operator.Build(response.data)));
  }

  updateOperatorById(id: string, payload: UpdateOperatorPayload): Observable<Operator> {
    return this.http
      .patch<ResponseDto<IOperator>>(`/management-users/${id}`, payload)
      .pipe(map((response) => Operator.Build(response.data)));
  }

  deleteOperatorById(id: string): Observable<void> {
    return this.http.delete<void>(`/management-users/${id}`);
  }

  createOperator(payload: CreateOperatorPayload): Observable<Operator> {
    return this.http
      .post<ResponseDto<IOperator>>(`/management-users`, payload)
      .pipe(map((response) => Operator.Build(response.data)));
  }

  // TODO: Move to auth
  sendResetPasswordLink(email: string): Observable<void> {
    return this.http.post<void>(`/auth/password-reset`, { email });
  }
}
