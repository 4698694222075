import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Store } from '@ngrx/store';
import { map, switchMap } from 'rxjs';
import { Nullable } from '../../../../shared/types/nullable.type';
import { BundleSummary } from '../models/bundle-summary.model';
import { SummaryService } from '../service/summary.service';
import { loadSummaryActions } from './summary.actions';

@Injectable()
export class SummaryEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly summaryService = inject(SummaryService);

  loadSummaryByID$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSummaryActions.loadSummaryByID),
      switchMap(({ id }) =>
        this.summaryService.getSummaryByBundleId(id).pipe(
          map((summary: Nullable<BundleSummary>) =>
            loadSummaryActions.loadSummaryByIDSuccess({
              summary,
            })
          )
        )
      )
    )
  );

  uploadSummaryCSV$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSummaryActions.uploadSummaryCSV),
      switchMap(({ id, file }) =>
        this.summaryService.uploadSummaryCsv(id, file).pipe(
          map(() =>
            loadSummaryActions.loadSummaryByID({
              id,
            })
          )
        )
      )
    )
  );

  validateFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSummaryActions.validateSummaryFields),
      switchMap(({ id, fields }) =>
        this.summaryService.validateFields(id, fields).pipe(
          map(() =>
            loadSummaryActions.loadSummaryByID({
              id,
            })
          )
        )
      )
    )
  );

  deleteSummaryByID$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSummaryActions.deleteSummaryByID),
      switchMap(({ id }) =>
        this.summaryService.deleteSummaryById(id).pipe(
          map(() =>
            loadSummaryActions.loadSummaryByID({
              id,
            })
          )
        )
      )
    )
  );

  generateSummaryByID$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSummaryActions.generateSummaryByID),
      switchMap(({ id }) =>
        this.summaryService.generateSummaryById(id).pipe(
          map(() =>
            loadSummaryActions.loadSummaryByID({
              id,
            })
          )
        )
      )
    )
  );
}
