import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import player from 'lottie-web';
import { provideLottieOptions } from 'ngx-lottie';

export function provideLottie(): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideLottieOptions({
      player: () => player,
    }),
  ]);
}
