import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { provideState } from '@ngrx/store';

import { provideEffects } from '@ngrx/effects';
import { BundleEffects } from './+state/bundle.effects';
import * as fromBundle from './+state/bundle.reducer';

export function provideBundle(): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideState(fromBundle.BUNDLE_FEATURE_KEY, fromBundle.bundleReducer),
    provideEffects(BundleEffects),
  ]);
}
