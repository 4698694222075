import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  private readonly http = inject(HttpClient);

  getReportByToken(token: string): Observable<Blob> {
    const params = new HttpParams().set('token', token);

    return (
      this.http
        // @ts-ignore
        .get(`/docs-highlight`, { params, responseType: 'arraybuffer' })
        .pipe(map((data: ArrayBuffer) => new Blob([data], { type: 'application/pdf' })))
    );
  }
}
